<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>投诉管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <!-- 卡片视图区域 -->
      <el-row type="flex">
        <el-row>
          <el-col style="display: flex;">
            <el-input v-model="pages.orderId" class="search-input" placeholder="订单号" clearable />
            <el-select v-model="pages.status" placeholder="申诉状态" class="search-select" >
              <el-option v-for="item in statusList" :key="item.value" :label="item.key" :value="item.value"/>
            </el-select>
            <el-button size="mini" @click="search" type="primary" style="margin-left: 10px;">搜索</el-button>
          </el-col>
        </el-row>
      </el-row>
      <el-table :data="orderList" style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="问题描述：">
                <span>{{ props.row.message}}</span>
              </el-form-item>
              <el-form-item label="联系电话：">
                <span>{{ props.row.phone}}</span>
              </el-form-item>
              <el-form-item label="处理反馈：">
                <span>{{ props.row.feedback}}</span>
              </el-form-item>
              <el-form-item label="反馈图片：">
                <el-image v-if="!!props.row.feedbackPic" :src="props.row.feedbackPic" :preview-src-list="[props.row.feedbackPic]"
                 style="width: 100px; height: 100px; border: none" />
              </el-form-item>
              <el-form-item label="凭证：">
                <div v-if="!!props.row.pics">
                  <el-image :src="item" v-for="(item,index) in props.row.pics.split(',')" :key="index" :preview-src-list="[item]"
                 style="width: 100px; height: 100px; border: none" />
                </div>
              </el-form-item>
              
            </el-form>
          </template>
        </el-table-column>

        <el-table-column label="申诉时间" prop="createDate" />
        <el-table-column label="订单ID" prop="orderId" />
        <el-table-column label="商品名称" prop="goodsTitle" />
        <el-table-column label="问题诉求" prop="appeal"/>
        <el-table-column label="申诉原因" prop="reason"/>
        <el-table-column label="申诉状态" prop="statusName"/>
        <!-- 操作 -->
        <el-table-column label="操作">
          <template slot-scope="props">
              <el-button v-if="props.row.status == 2222" @click="_feedback(props.row)" type="primary">立即处理</el-button>
              <el-button v-if="props.row.status == 3333" @click="_close(props.row.id)" type="danger">关闭</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="pages.currentPage"
                     :page-sizes="[3, 5, 10, 15]"
                     :page-size="pages.pagesize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </el-card>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="50%" >
      <el-form label-width="100px">
        <el-form-item label="反馈内容" >
          <el-input v-model="feedback"></el-input>
        </el-form-item>
        <el-form-item label="反馈图片" >
          <el-upload
            :action="uploadURL"
            list-type="picture-card"
            :on-preview="mainHandlePictureCardPreview"
            :on-remove="mainHleRemove"
            :headers="headerObj"
            :file-list="fileList"
            :on-success="mainHandleSuccess"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="picDialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>
      </el-form>
      <!-- 底部 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
//
<script>
export default {
 
  data () {
    return {
      pages: {
        currentPage: 1,
        pageSize: 10,
        orderId: '',
        status: null,

      },
      dialogVisible: false,
      // 总条数
      total: 0,
      // 订单列表
      orderList: [],
      statusList: [
        {key: '全部', value: null},
        {key: '加急处理中', value: '2222'},
        {key: '商家已处理', value: '3333'},
        {key: '投诉关闭', value: '4444'},
      ],  
      handleId: '',
      feedback: '',
      pic: '',
      fileList: [],
      uploadURL: '/api/file/uploadFile',
      headerObj: {
        Authorization: window.localStorage.getItem('token'),
      },
      dialogImageUrl: null,
      picDialogVisible: false,
    }
  },
  created () {
    // 订单列表
    this.GetOrders()
  },
  methods: {
    search(){
      this.pages.currentPage = 1;
      this.GetOrders();
    },
    // 订单列表
    GetOrders () {
      this.$api.GetComplaintListToPage(this.pages).then((res) => {
        if (res.code !== 200) return this.$message.error('请求列表失败：' + (res.message || ''))
        this.orderList = res.data.records
        this.total = res.data.total
      })
    },

    _feedback(data){
      this.handleId = data.id;
      this.dialogVisible = true;
    },

    // 图片预览
    mainHandlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.picDialogVisible = true
    },


    // 图片删除
    mainHleRemove(file, fileList) {
      this.pic = null
      this.fileList = []
    },

    // 图片上传成功之后的操作
    mainHandleSuccess(res) {
      this.pic = res.data
      this.fileList = [{url: res.data}]
    },

    _close(id){
      this.$confirm('确定要关闭该投诉吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warn',
        callback: action => {
          if (action === 'confirm') {
            this.$api.CloseComplaint({id}).then((res) => {
                if (res.code !== 200) return this.$message.error('操作失败:' + (res.message || ''))
                this.GetOrders();
              this.$message.success('操作成功')
            })
          }
        }
      })
    },
    onSubmit(){
      if(!this.feedback) return this.$message.error('请输入反馈内容')
      const params = {
        id: this.handleId,
        feedback: this.feedback,
        feedbackPic: this.pic,
      }
      this.$api.ExamineComplaint(params).then((res) => {
          if (res.code !== 200) {
            return this.$message.error('操作失败:' + (res.message || ''))
          }
          this.GetOrders();
          this.$message.success('操作成功')
          this.dialogVisible = false;
      })
    },

    // 选中页面值
    handleSizeChange (newSize) {
      console.log(newSize)
      this.pages.pageSize = newSize
      this.GetOrders()
    },
    //当页面变动的时候
    handleCurrentChange (NewPage) {
      console.log(NewPage)
      this.pages.currentPage = NewPage
      this.GetOrders()
    },
    

  },
}
</script>
<style lang="scss" scoped>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.el-button {
  padding: 10px;
}

.search-input {
  width:150px;
    margin-right: 5px;
}
.search-select{
  width:140px;
  margin-right: 5px;
}
.search-date{
  width:135px;
}
</style>
